var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "animate__animated animate__pulse animate__infinite": _vm.warning,
      },
    },
    [
      _c("span", { class: { warning: _vm.warning } }, [
        _vm._v(_vm._s(_vm._f("prettify")(_vm.time))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }