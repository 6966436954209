<template>
  <b-form-group :label="label" label-for="input" :label-class="{ 'required-field': required }">
    <b-overlay :show="loading" rounded="sm">
      <b-dropdown
        class="w-100"
        variant="empty"
        toggle-class="p-0"
        menu-class="mt-2 a-shadow-sm"
        no-caret
      >
        <template slot="button-content">
          <b-form-input
            :placeholder="placeholder"
            readonly
            :state="validate && v.$error"
            v-model="selected[itemValue]"
            autocomplete="off"
            @input="v.$touch()"
            v-tooltip="{
              content: error,
              show: validate && v.$error,
              trigger: 'manual',
              placement: 'bottom',
              classes: ['light']
            }"
          />
        </template>
        <b-dropdown-item
          v-for="(item, index) in options"
          :key="`item-${index}`"
          :active="setItemActive(index)"
          @click="onItemClick(item)"
        >
          <span>{{ item.name }}</span>
        </b-dropdown-item>
      </b-dropdown>
      <template #overlay>
        <pulse-loader :loading="loading" :color="fill" size="10px"></pulse-loader>
      </template>
    </b-overlay>
  </b-form-group>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
export default {
  components: {
    PulseLoader
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'Your input'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    value: {
      type: Object,
      default: null
    },
    v: {
      type: Object,
      required: false
    },
    validate: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fill: variables.primaryColor,
      selectedItem: null
    }
  },
  methods: {
    onItemClick(item) {
      let self = this
      self.selectedItem = item
      this.$emit('input', item)
    },
    setItemActive(index) {
      let self = this
      return self.selectedItem ? index === self.options.indexOf(self.selectedItem) : false
    }
  },
  computed: {
    selected: {
      get() {
        let self = this
        let item = self.value
          ? self.value
          : {
              [self.itemValue]: ''
            }

        return item
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.invalid-tooltip {
  display: block;
}
</style>
