export const pageVisibilityMixin = {
  data() {
    return {
      pageHiddenTimeStamp: 0,
      pageVisibleTimeStamp: 0,
      pageVisibilityTimeGap: 0
    }
  },
  methods: {
    visibilityChangeHandler(value) {
      let self = this
      const visibility = document.visibilityState
      if (visibility === 'hidden') {
        self.pageHiddenTimeStamp = value.timeStamp
      }

      if (visibility === 'visible') {
        self.pageVisibleTimeStamp = value.timeStamp
        self.pageVisibilityTimeGap = self.pageVisibleTimeStamp - self.pageHiddenTimeStamp
      }
    }
  },
  created() {
    document.addEventListener('visibilitychange', this.visibilityChangeHandler, false)
  },
  beforeDestroy() {
    let self = this
    self.pageHiddenTimeStamp = 0
    self.pageVisibleTimeStamp = 0
    self.pageVisibilityTimeGap = 0
    document.removeEventListener('visibilitychange', self.visibilityChangeHandler)
  }
}
